<template>
  <div v-if="isVisible" class="modal-overlay" @click="handleClickOutside">
  <div class="modal-section" @click.stop>
    <div class="modal-content">

      <div class="headerInfo">
        <div class="tipoEvento">
          <img :src="getIconSrc(event?.type?.id)" alt="icono evento" />
          <p v-if="event?.type?.name "> {{ event?.type?.name || '' }} </p>
        </div>
        
        <p v-if="event?.start"> {{ formatDay(event?.start) }} </p>
        <span class="close" @click="closeModal">&times;</span>
      </div> 

      <hr class="custom-line">

      <div class="photoTitle">
        <div class="modal-img">
        <img :src="event?.imageUrl ? event?.imageUrl : getIconSrc(event?.type?.id)" alt="Imagen del evento"  :class="{ 'small-icon': !event?.imageUrl }"
        class="event-image"/></div>
        <div class="text-container">
          <h2> {{ event?.name || 'Evento sin nombre' }} </h2>
          <div class="ubicacion">
            <img :src= "ubicacion"  alt="icono ubicacion" />
            <p> {{ event?.place?.name || 'Ubicación no disponible' }} </p>
          </div>
        </div>
      </div>

      <hr class="custom-line">

      <div class="event-info">
        <p v-if="event?.place?.timetable && event?.type.id == '9554edd0-52b9-44fa-b972-f537cc566508'"><strong>{{ $t('modal.timetable') }}:</strong>  {{ event?.place?.timetable }}</p>
        <p v-if="event?.start && event?.type.id != '9554edd0-52b9-44fa-b972-f537cc566508'"><strong>{{ $t('modal.inicio') }}:</strong>  {{ formatHour(event?.start) }}</p>
        <p v-if="event?.end && event?.type.id != '9554edd0-52b9-44fa-b972-f537cc566508'"><strong>{{ $t('modal.fin') }}:</strong>  {{ formatHour(event?.end) }}*</p>
        <p v-if="event?.price"><strong>{{ $t('modal.precio') }}:</strong> {{ event?.price  }}</p>
        <p v-if="event?.langs?.length > 0 && String(event?.langs[0].id) !== '--'">
          <strong>{{ $t('modal.idioma') }}:</strong>
          {{ event?.langs.map(lang => lang.name).join(', ') }}
        </p>
        <p v-if="event?.place?.type.name"><strong>{{ $t('modal.placeType') }}:</strong> {{ event?.place?.type.name  }}</p>
        <p v-if="event?.place?.address || event?.place?.town?.name || event?.place?.postcode"><strong>{{ $t('modal.direccion') }}:</strong> {{ event?.place?.address }} {{ event?.place?.town?.name }} ({{ event?.place?.postcode }}) </p>   
      </div>
      <div class="extras">
        <p v-if="event?.type.id != '9554edd0-52b9-44fa-b972-f537cc566508' && event?.end" class="textoPie">* {{ $t('modal.aproximada') }}</p>
        <p v-if="event?.url" class="eventUrl" style="cursor: pointer;" @click="openEvent(event?.url)">{{ $t('modal.moreInfo') }}  <img :src="getArrowSrc(event?.type?.id)" alt="icono flecha" class="flechaUrl" /></p>
      </div>
      <hr class="custom-line">
   
      <div class="distance">
        <div class="distance-textButton">
          <p v-if="distance"> {{ $t('modal.distancia1') }} {{ distance }} {{ $t('modal.distancia2') }} </p>
          <button class="secondary-transparent" @click="abrirMapa">
            {{ $t('modal.HowToGet') }}
              <img :src="mapVisible ? up : down" alt="Toggle Map">
            </button>
        </div>
      <div v-if="mapVisible" class="map-container">
      <MapComponent
          class="map-component"
          v-if="mapVisible"
          :lat="event?.place.point.lat"
          :lon="event?.place.point.lon"
          :eventName="event?.name"
        />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, nextTick, watch, onMounted, getCurrentInstance } from 'vue';
import { format, parse } from 'date-fns'; 
import MapComponent from './MapComponent.vue'; 
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { filterState } from '../filterState';

import ubicacion from '../assets/svg/ubicacion.svg';
import musica from '../assets/svg/evento_a3f8b76f-1830-43a6-bc4a-474b5f55bd61.svg';
import teatro from '../assets/svg/evento_55c1c840-2e9a-40a2-89fb-008d2989de19.svg';
import cine from '../assets/svg/evento_bd226083-2579-496d-a1dd-1aa9a08a6855.svg';
import danza from '../assets/svg/evento_cfb8cd34-9d1a-42ba-bcde-c1d4f992de34.svg';
import bertsolarismo from '../assets/svg/evento_a6329d46-f5ae-4b5e-82ba-facf7ffb6919.svg';
import infantil from '../assets/svg/evento_f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c.svg';
import taller from '../assets/svg/evento_6eb6e65a-3a54-4028-8664-37f693b3fe5f.svg';
import exposicion from '../assets/svg/evento_9554edd0-52b9-44fa-b972-f537cc566508.svg';
import conferencia from '../assets/svg/evento_432671dd-b7e0-4e04-8938-a761f815e89f.svg';
import jornada from '../assets/svg/evento_a72db43b-b4ee-454c-aad5-01a61cbaf361.svg';
import concurso from '../assets/svg/evento_86414ffb-4301-4678-9da3-43c3944edc38.svg';
import feria from '../assets/svg/evento_bc3fab61-c2b2-4a7f-a240-27513aa7b0e3.svg';
import fiesta from '../assets/svg/evento_59143b39-414e-4658-9348-c9463716365d.svg';
import festival from '../assets/svg/evento_ad32f50b-8eb5-453a-a5a7-2ae1264a99cc.svg';
import otras from '../assets/svg/evento_e3450f47-c20a-4409-8116-c25b18239af6.svg';
import up from '../assets/svg/upGray.svg';
import down from '../assets/svg/downGray.svg';

import fmusica from '../assets/svg/flecha_a3f8b76f-1830-43a6-bc4a-474b5f55bd61.svg';
import fteatro from '../assets/svg/flecha_55c1c840-2e9a-40a2-89fb-008d2989de19.svg';
import fcine from '../assets/svg/flecha_bd226083-2579-496d-a1dd-1aa9a08a6855.svg';
import fdanza from '../assets/svg/flecha_cfb8cd34-9d1a-42ba-bcde-c1d4f992de34.svg';
import fbertsolarismo from '../assets/svg/flecha_a6329d46-f5ae-4b5e-82ba-facf7ffb6919.svg';
import finfantil from '../assets/svg/flecha_f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c.svg';
import ftaller from '../assets/svg/flecha_6eb6e65a-3a54-4028-8664-37f693b3fe5f.svg';
import fexposicion from '../assets/svg/flecha_9554edd0-52b9-44fa-b972-f537cc566508.svg';
import fconferencia from '../assets/svg/flecha_432671dd-b7e0-4e04-8938-a761f815e89f.svg';
import fjornada from '../assets/svg/flecha_a72db43b-b4ee-454c-aad5-01a61cbaf361.svg';
import fconcurso from '../assets/svg/flecha_86414ffb-4301-4678-9da3-43c3944edc38.svg';
import fferia from '../assets/svg/flecha_bc3fab61-c2b2-4a7f-a240-27513aa7b0e3.svg';
import ffiesta from '../assets/svg/flecha_59143b39-414e-4658-9348-c9463716365d.svg';
import ffestival from '../assets/svg/flecha_ad32f50b-8eb5-453a-a5a7-2ae1264a99cc.svg';
import fotras from '../assets/svg/flecha_e3450f47-c20a-4409-8116-c25b18239af6.svg';

const R = 6371;

export default defineComponent({
  components: {
    MapComponent,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
    },
  }, emits: ['close'],
  setup(props, { emit }) {


    const formatHour = (dateString) => {
      const date = parse(dateString, 'yy-MM-dd HH:mm', new Date());
      return format(date, 'HH:mm'); // Formato de hora
    };

    const formatDay = (dateString) => {
      const date = parse(dateString, 'yy-MM-dd HH:mm', new Date());
      return format(date, 'yyyy-MM-d'); // Formato de día
    };

    
    const getIconSrc = (id) => {
    switch (id) {
      case 'a3f8b76f-1830-43a6-bc4a-474b5f55bd61': // música
        return musica;
      case '55c1c840-2e9a-40a2-89fb-008d2989de19': // teatro
        return teatro;
      case 'bd226083-2579-496d-a1dd-1aa9a08a6855': // cine
        return cine;
      case 'cfb8cd34-9d1a-42ba-bcde-c1d4f992de34': // danza
        return danza;
      case 'a6329d46-f5ae-4b5e-82ba-facf7ffb6919': // bertsolarismo
        return bertsolarismo;
      case 'f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c': // infantil
        return infantil;
      case '6eb6e65a-3a54-4028-8664-37f693b3fe5f': // taller
        return taller;
      case '9554edd0-52b9-44fa-b972-f537cc566508': // exposición
        return exposicion;
      case '432671dd-b7e0-4e04-8938-a761f815e89f': // conferencia
        return conferencia;
      case 'a72db43b-b4ee-454c-aad5-01a61cbaf361': // jornada
        return jornada;
      case '86414ffb-4301-4678-9da3-43c3944edc38': // concurso
        return concurso;
      case 'bc3fab61-c2b2-4a7f-a240-27513aa7b0e3': // feria
        return feria;
      case '59143b39-414e-4658-9348-c9463716365d': // fiesta
        return fiesta;
      case 'ad32f50b-8eb5-453a-a5a7-2ae1264a99cc': // festival
        return festival;
      case 'e3450f47-c20a-4409-8116-c25b18239af6': // otras
        return otras;
      default:
        return otras; // Icono por defecto si no hay coincidencia
    }
  };

  const getArrowSrc = (id) => {
            switch (id) {
                case 'a3f8b76f-1830-43a6-bc4a-474b5f55bd61': // música
                    return fmusica;
                case '55c1c840-2e9a-40a2-89fb-008d2989de19': // teatro
                    return fteatro;
                case 'bd226083-2579-496d-a1dd-1aa9a08a6855': // cine
                    return fcine;
                case 'cfb8cd34-9d1a-42ba-bcde-c1d4f992de34': // danza
                    return fdanza;
                case 'a6329d46-f5ae-4b5e-82ba-facf7ffb6919': // bertsolarismo
                    return fbertsolarismo;
                case 'f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c': // infantil
                    return finfantil;
                case '6eb6e65a-3a54-4028-8664-37f693b3fe5f': // taller
                    return ftaller;
                case '9554edd0-52b9-44fa-b972-f537cc566508': // exposición
                    return fexposicion;
                case '432671dd-b7e0-4e04-8938-a761f815e89f': // conferencia
                    return fconferencia;
                case 'a72db43b-b4ee-454c-aad5-01a61cbaf361': // jornada
                    return fjornada;
                case '86414ffb-4301-4678-9da3-43c3944edc38': // concurso
                    return fconcurso;
                case 'bc3fab61-c2b2-4a7f-a240-27513aa7b0e3': // feria
                    return fferia;
                case '59143b39-414e-4658-9348-c9463716365d': // fiesta
                    return ffiesta;
                case 'ad32f50b-8eb5-453a-a5a7-2ae1264a99cc': // festival
                    return ffestival;
                case 'e3450f47-c20a-4409-8116-c25b18239af6': // otras
                    return fotras;
                default:
                    return fotras; // Icono por defecto si no hay coincidencia
            }
        };

  const mapVisible = ref(false); // Variable reactiva para controlar la visibilidad del mapa
  const mapContainer = ref(null); 
  const distance = ref(null); 

  const instance = getCurrentInstance();
  const { proxy } = instance;

  const openEvent = (url) => {
    if (confirm(proxy.$t('modal.newPage'))) {
   // if (url) {
      window.open(url, '_blank');
    }
  };

  const closeModal = () => {
      // Asegúrate de que el mapa se cierre
      mapVisible.value = false;
      emit('close'); // Emite el evento hacia el padre
    };
    
    const abrirMapa = async () => {
    mapVisible.value = !mapVisible.value; // Alternar la visibilidad del mapa
      if (mapVisible.value) {
        // Espera a que el DOM se actualice para hacer scroll
        await nextTick();
        const modalContent = document.querySelector('.modal-content');
        modalContent.scrollTo({
          top: modalContent.scrollHeight,
          behavior: 'smooth'
        });
      }
};

    const degToRad = (deg) => {
      return deg * (Math.PI / 180);
    };

    const calculateDistance = () => {


  const userLat = filterState.latitude;
  const userLon = filterState.longitude;
  const eventLat = props.event?.place?.point?.lat;
  const eventLon = props.event?.place?.point?.lon;

  if (
    filterState.latitude == null ||
    filterState.longitude == null
  ) {
    distance.value = null; // No hay coordenadas, no se puede calcular
    return;
  }

  // Conversión de grados a radianes
  const dLat = degToRad(eventLat - userLat);
  const dLon = degToRad(eventLon - userLon);

  const lat1 = degToRad(userLat);
  const lat2 = degToRad(eventLat);

  // Aplicar la fórmula de Haversine
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distancia en kilómetros
  const distanceInKm = R * c;

  // Verifica si distanceInKm es un número antes de asignarlo
  distance.value = !isNaN(distanceInKm) ? distanceInKm.toFixed(2) : null;
};

    // Observa los cambios en filterState
    watch(
      () => [filterState.latitude, filterState.longitude, props.event],
      () => {
        // Llama a calculateDistance cuando filterState tenga nuevos valores
        calculateDistance();
      },
      { immediate: true } // Llama a la función inmediatamente al montar el componente
    );

 onMounted(() => {
  calculateDistance(); // Calcular distancia al montar el componente 
});



    const handleClickOutside = (event) => {
      // Si se hace clic en el overlay (fondo) y no en la sección del modal, cerrar el modal
      if (event?.target.classList.contains('modal-overlay')) {
        closeModal();
      }
    };

    return {
      closeModal,
      format,
      formatHour,
      formatDay,
      props,
      getIconSrc,
      ubicacion,
      mapVisible,
      abrirMapa,
      up,
      down,
      mapContainer,
      calculateDistance,
      distance,
      handleClickOutside,
      openEvent,
      getArrowSrc,
    };
  },
});
</script>

<style>

</style>
