<template>
  <div class="language-selector">
    <a 
      v-for="lang in languages" 
      :key="lang.code" 
      @click="changeLanguage(lang.code)" 
      :class="{ active: lang.code === selectedLanguage }"
    >
      {{ lang.label }}
    </a>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { filterState } from '../filterState'; 
import { useI18n } from 'vue-i18n'; // Importa el composable useI18n

export default defineComponent({
  props: {
    selectedLanguage: {
      type: String,
      default: 'eu'
    }
  },
  emits: ['language-changed'],
  setup(props, { emit }) {
    const { locale } = useI18n(); // Obtén el locale desde useI18n
    const selectedLanguage = ref(props.selectedLanguage); // Hacer reactivo el selectedLanguage

    const languages = [
      { code: 'eu', label: 'EU' },
      { code: 'es', label: 'ES' },
      { code: 'en', label: 'EN' }
    ];

    const changeLanguage = (lang) => {
      selectedLanguage.value = lang; // Actualiza el idioma seleccionado localmente
      filterState.language = lang; // Actualizar el estado global de filterState
      locale.value = lang; // Cambiar el idioma usando el composable de i18n
      emit('language-changed', lang); // Emitir el evento `language-changed` con el idioma seleccionado
    };

    return {
      languages,
      selectedLanguage,
      changeLanguage
    };
  }
});
</script>
