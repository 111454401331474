<template>
  <main class="main-section">
    <article class="hero-section">
      <div class="hero-content">
        <h1 class="hero-text">{{ $t('events.find') }}</h1>
        <!-- Botón personalizado para abrir el DatePicker -->
        <DatePicker @input="onDateSelected" value="date" v-model="date" :minDate="minDate" :maxDate="maxDate"
          showButtonBar class="secondary-transparent datepicker-input" :placeholder="$t('events.chooseDate')"
          inputId="over_label" showIcon fluid iconDisplay="input" alt="buscar por fecha" />
      </div>
    </article>

    <!-- Botones de selección de fecha -->
    <section class="date-section">
      <!-- Botón para retroceder días -->
      <button class="date-button-arrow" @click="prevDays" :disabled="isToday"><img src="../assets/svg/left.svg"
          alt="Itinerario anterior" /></button>

      <!-- Botones para los tres días -->
      <button :class="['date-button', { selected: isSelected(day) }]" @click="selectDate(day)"
        :disabled="day > '2025-02-28'">
        <span class="month">{{ formatMonth(day) }}</span>
        <span class="day">{{ formatDay(day) }}</span>
      </button>

      <button :class="['date-button', { selected: isSelected(nextDay) }]" @click="selectDate(nextDay)"
        :disabled="nextDay > '2025-02-28'">
        <span class="month">{{ formatMonth(nextDay) }}</span>
        <span class="day">{{ formatDay(nextDay) }}</span>
      </button>

      <button :class="['date-button', { selected: isSelected(dayAfterNext) }]" @click="selectDate(dayAfterNext)"
        :disabled="dayAfterNext > '2025-02-28'">
        <span class="month">{{ formatMonth(dayAfterNext) }}</span>
        <span class="day">{{ formatDay(dayAfterNext) }}</span>
      </button>

      <!-- Botón para avanzar días -->
      <button class="date-button-arrow" @click="nextDays" :disabled="isMaxDate"><img src="../assets/svg/right.svg"
          alt="Itinerario anterior" /></button>
    </section>

    <!-- Contenedor de filtros en fila -->
    <div class="filters">
      <div v-if="filtersReady" class="filters-section">
        <DistanceFilter :selectedDistance="selectedDistance" :numberOfEvents="numberOfEvents"
          @update:selectedDistance="onDistanceChange" />
        <TimeFilter :selectedStartTime="selectedStartTime" :selectedEndTime="selectedEndTime"
          :numberOfEvents="numberOfEvents" @update:selectedTimes="onTimeChange" />

        <TypeFilter :eventTypesResult="eventTypesResult" :numberOfEvents="numberOfEvents"
          @update:selectedTypes="onTypeChange" />
        <PlaceTypeFilter :placeTypesResult="placeTypesResult" :numberOfEvents="numberOfEvents"
          @update:selectedPlaceTypes="onPlaceTypeChange" />
        <LanguageFilter :languagesResult="languagesResult" :numberOfEvents="numberOfEvents"
          @update:selectedLanguages="onLanguageChange" />

        <ProvinceFilter :provincesResult="provincesResult" :numberOfEvents="numberOfEvents"
          @update:selectedProvinces="onProvinceChange" />
        <TownFilter :townsResult="townsResult" :numberOfEvents="numberOfEvents" @update:selectedTowns="onTownChange" />
        <PostcodeFilter :postcodesResult="postcodesResult" :numberOfEvents="numberOfEvents"
          @update:selectedPostcodes="onPostcodeChange" />
        <PlaceFilter :placesResult="placesResult" :numberOfEvents="numberOfEvents"
          @update:selectedPlaces="onPlaceChange" />
      </div>
    </div>
    <hr class="custom-line">

    <div class="itineraries-section">
      <h4 class="itineraries-info">
        <!-- Cuando no hay eventos -->
        <span v-if="summary.eventsCount === 0">
          {{ $t('summaryNoEventsFound') }}
        </span>

        <!-- Cuando hay un solo evento y varios municipios -->
        <span v-else-if="summary.eventsCount === 1 && summary.townsCount > 1">
          {{ $t('summarySingleEvent0') }}{{ summary.eventsCount }} {{ $t('summarySingleEvent') }} {{ summary.townsCount
          }} {{ $t('summary2') }}
          {{ summary.startTimeMin }}{{ $t('summary3') }} {{ summary.endTimeMax }}{{ $t('summary31') }}<span
            v-if="summary.distanceMax !== null">
            {{ $t('summary4') }} {{ parseFloat((summary.distanceMax / 1000).toFixed(2)) }} {{ $t('summary5') }}
          </span>
        </span>

        <!-- Cuando hay varios eventos y un solo municipio -->
        <span v-else-if="summary.eventsCount > 1 && summary.townsCount === 1">
          {{ summary.eventsCount }} {{ $t('summary1') }} {{ $t('summarySingleTown1') }} {{ summary.townsCount }}{{
            $t('summarySingleTown2') }}
          {{ summary.startTimeMin }}{{ $t('summary3') }} {{ summary.endTimeMax }}{{ $t('summary31') }}<span
            v-if="summary.distanceMax !== null">
            {{ $t('summary4') }} {{ parseFloat((summary.distanceMax / 1000).toFixed(2)) }} {{ $t('summary5') }}
          </span>
        </span>

        <!-- Cuando hay un solo evento y un solo municipio -->
        <span v-else-if="summary.eventsCount === 1 && summary.townsCount === 1">
          {{ $t('summarySingleEvent0') }} {{ summary.eventsCount }} {{ $t('summarySingleEvent') }} {{
            $t('summarySingleTown1') }} {{ summary.townsCount }}{{ $t('summarySingleTown2') }}
          {{ summary.startTimeMin }}{{ $t('summary3') }} {{ summary.endTimeMax }}{{ $t('summary31') }}<span
            v-if="summary.distanceMax !== null">
            {{ $t('summary4') }} {{ parseFloat((summary.distanceMax / 1000).toFixed(2)) }} {{ $t('summary5') }}
          </span>
        </span>

        <!-- Cuando hay varios eventos y varios municipios -->
        <span v-else>
          {{ summary.eventsCount }} {{ $t('summary1') }} {{ summary.townsCount }} {{ $t('summary2') }}
          {{ summary.startTimeMin }}{{ $t('summary3') }} {{ summary.endTimeMax }}{{ $t('summary31') }}<span
            v-if="summary.distanceMax !== null">
            {{ $t('summary4') }} {{ parseFloat((summary.distanceMax / 1000).toFixed(2)) }} {{ $t('summary5') }}
          </span>
        </span>
      </h4>


      <!-- Select para los municipios con eventos disponibles -->
      <div id="townSelectContainer" v-if="townsResult.length > 0">
        <div v-if="townsResult.length > 0" class="custom-select-wrapper">
          <select id="townSelect" class="secondary-button custom-select" v-model="selectedTownId"
            @change="updateSelectedIndex" style="cursor: pointer;">
            <option v-for="town in townsResult" :key="town.id" :value="town.id">
              {{ town.name }}
            </option>
          </select>

        </div>
      </div>
      <div class="flex-container">
        <!-- Tabla de itinerarios con horarios -->
        <div class="itineraries" v-if="timeSlots.length > 0">
          <table class="itinerary-table">
            <thead>
              <tr>
                <th class="horasTabla"></th>
                <th class="tituloItinerario"
                  v-for="itineraryIndex in Math.min(itinerariesPerPage, itinerariesCount - (currentPage * itinerariesPerPage))"
                  :key="itineraryIndex">{{
                    $t('itineraries.itinerary') }} {{ (currentPage * itinerariesPerPage) + itineraryIndex }} </th>
              </tr>
            </thead>

            <tbody>
              <!-- Generar las 36 filas para los horarios de media hora -->
              <template v-for="time in timeSlots" :key="time">
                <tr>
                  <!-- Primera columna: Horarios -->
                  <td class="horasTabla">{{ formatTimeSlot(time) }}</td>

                  <!-- Generar celdas para cada itinerario por fila -->
                  <template
                    v-for="itineraryIndex in Math.min(itinerariesPerPage, itinerariesCount - (currentPage * itinerariesPerPage))"
                    :key="itineraryIndex">
                    <!-- Buscar los eventos para el itinerario y horario -->
                    <template
                      v-for="event in getEventsForTimeItineraryAndTown(time, itineraryIndex, selectedTownId, currentPage)"
                      :key="event.id + time">
                      <!-- Mostrar evento con rowspan si aplica -->
                      <td v-if="event.cellsOccupied > 0" :key="event.id" :rowspan="event.cellsOccupied"
                        class="event-card" style="cursor: pointer;" @click="openModal(event)"
                        :class="{ 'offset-15': event.offsetMinutes === 15 }" :style="{
                          '--event-color': getEventColor(event.type?.id),
                          'position': 'relative'
                        }">
                        <img :src="getIconSrc(event.type.id)" alt="icono evento" class="iconoEvento" />
                        <div class="event-info">
                          <h4>{{ event.name }}</h4>
                          <p>{{ event.place.name }}</p>
                        </div>
                        <img :src="getArrowSrc(event.type.id)" alt="icono flecha" class="iconoFlecha" />
                      </td>
                      <td v-if="event.name === 'Sin Evento'" :key="'empty-' + event.id + time" class="empty-event-cell">
                      </td>

                    </template>
                  </template>
                </tr>
              </template>
              <tr>
                <td class="horasTabla">{{ formatTimeSlot(timeSlots[timeSlots.length - 1] + 30) }}</td>
              </tr>
            </tbody>
          </table>

        </div>
        <div v-else>
          <p>{{ $t('events.noEvents') }}</p>
        </div>


        <!-- Leyenda de eventos -->
        <div class="legend-section">
          <div v-for="(type) in Array.from(addedEventTypes)" :key="type.id" class="legend-content">
            <img :src="getIconSrc(type.id)" alt="Icono del tipo de evento" />
            <span>{{ type.name }} </span>
          </div>
        </div>
      </div>

    </div>


    <!-- Controles de paginación -->
    <div class="pagination-section">
      <button class="pagination-button" @click="previousPage" :disabled="currentPage === 0">
        <img :src="leftArrowSrc" alt="Itinerario anterior" />
      </button>
      <h4 v-if="summary.eventsCount !== 0" class="pagination-text"> {{ currentPage + 1 }} / {{ totalPages }}</h4>
      <button class="pagination-button" @click="nextPage" :disabled="currentPage >= totalPages - 1">
        <img :src="rightArrowSrc" alt="Siguiente itinerario" />
      </button>
    </div>


    <hr class="custom-line">

    <div class="event-list">
      <RandomEvents :lang="lang" />
    </div>

    <hr class="custom-line">

    <section v-if="showContactForm" class="contact-section">
      <div class="contact-container">
        <div class="contact-image"></div>
        <div class="contact-content">
          <p>{{ $t('contact.questions') }}</p>
          <p>{{ $t('contact.suggestions') }}</p>
          <p>{{ $t('contact.organize') }}</p>
          <h3>{{ $t('contact.contact') }}</h3>

          <form @submit.prevent="sendEmail" class="contact-form">

            <label for="subject">{{ $t('contact.subject') }}:</label>
            <input type="text" v-model="form.subject" id="subject" :placeholder="$t('contact.subjectInput')" required />

            <label for="message">{{ $t('contact.message') }}:</label>
            <textarea v-model="form.message" id="message" :placeholder="$t('contact.messageInput')" required></textarea>
            <button type="submit" class="secondary-transparent" @click="sendEmail">
              {{ $t('contact.sendButton') }}
              <img :src="buttonImage" :class="{ 'rotate': isSending }" />
            </button>
          </form>

          <!-- Notificación -->
          <transition name="fade">
            <div v-if="notification.show" :class="['notification', notification.isError ? 'error' : 'success']">
              <div class="notification-header">
                <span>{{ notification.isError ? $t('notification.error') : $t('notification.send') }}</span>
                <button class="close-btn" @click="closeNotification">✕</button>
              </div>
              <p>{{ notification.message }}</p>
            </div>
          </transition>
        </div>
      </div>
    </section>

  </main>

  <PrivacyPolicyModal :isVisible="isPrivacyModalVisible" @close="closePrivacyModal" />

  <footer class="footer-section">
    <div class="footer-content">
      <img src="../assets/img/TopaLogo.png" alt="Logo Toapenda" style="cursor: pointer;" @click="reloadPage" />
      <div class="footer-text">
        <p @click="openPrivacyModal" style="cursor: pointer;" class="underline-link">{{ $t('privacy.title') }}</p>
        <!--  <p style="cursor: pointer;" class="underline-link">Contacto</p>-->
      </div>
      <p class="brand">© 2024 Topagenda</p>

    </div>
  </footer>

  <!-- Modal Component -->
  <EventModal :isVisible="isModalVisible" :event="selectedEvent" @close="closeModal"
    :style="{ '--event-color': getEventColor(selectedEvent.type?.id) }" />


</template>


<script>
import { ref, watchEffect, watch, computed, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { filterState } from '../filterState';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import DatePicker from 'primevue/datepicker';
import { usePrimeVue } from 'primevue/config';
import emailjs from '@emailjs/browser';

import LanguageSelector from './LanguageSelector.vue';
import TimeFilter from './TimeFilter.vue';
import ProvinceFilter from './ProvinceFilter.vue';
import TypeFilter from './TypeFilter.vue';
import LanguageFilter from './LanguageFilter.vue';
import TownFilter from './TownFilter.vue';
import PostcodeFilter from './PostcodeFilter.vue';
import PlaceFilter from './PlaceFilter.vue';
import PlaceTypeFilter from './PlaceTypeFilter.vue';
import DistanceFilter from './DistanceFilter.vue';
import EventModal from './EventModal.vue';
import LeftBlack from '../assets/svg/LeftBlack.svg';
import LeftGray from '../assets/svg/LeftGray.svg';
import RightBlack from '../assets/svg/RightBlack.svg';
import RightGray from '../assets/svg/RightGray.svg';
import LeftGreen from '../assets/svg/LeftGreen.svg';
import LeftSage from '../assets/svg/LeftSage.svg';
import RightGreen from '../assets/svg/RightGreen.svg';
import RightSage from '../assets/svg/RightSage.svg';
import RandomEvents from './RandomEvents.vue';
import PrivacyPolicyModal from './PrivacyPolicyModal.vue';
import time from '../assets/svg/time.svg';
import right from '../assets/svg/right.svg';

import musica from '../assets/svg/evento_a3f8b76f-1830-43a6-bc4a-474b5f55bd61.svg';
import teatro from '../assets/svg/evento_55c1c840-2e9a-40a2-89fb-008d2989de19.svg';
import cine from '../assets/svg/evento_bd226083-2579-496d-a1dd-1aa9a08a6855.svg';
import danza from '../assets/svg/evento_cfb8cd34-9d1a-42ba-bcde-c1d4f992de34.svg';
import bertsolarismo from '../assets/svg/evento_a6329d46-f5ae-4b5e-82ba-facf7ffb6919.svg';
import infantil from '../assets/svg/evento_f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c.svg';
import taller from '../assets/svg/evento_6eb6e65a-3a54-4028-8664-37f693b3fe5f.svg';
import exposicion from '../assets/svg/evento_9554edd0-52b9-44fa-b972-f537cc566508.svg';
import conferencia from '../assets/svg/evento_432671dd-b7e0-4e04-8938-a761f815e89f.svg';
import jornada from '../assets/svg/evento_a72db43b-b4ee-454c-aad5-01a61cbaf361.svg';
import concurso from '../assets/svg/evento_86414ffb-4301-4678-9da3-43c3944edc38.svg';
import feria from '../assets/svg/evento_bc3fab61-c2b2-4a7f-a240-27513aa7b0e3.svg';
import fiesta from '../assets/svg/evento_59143b39-414e-4658-9348-c9463716365d.svg';
import festival from '../assets/svg/evento_ad32f50b-8eb5-453a-a5a7-2ae1264a99cc.svg';
import otras from '../assets/svg/evento_e3450f47-c20a-4409-8116-c25b18239af6.svg';

import fmusica from '../assets/svg/flecha_a3f8b76f-1830-43a6-bc4a-474b5f55bd61.svg';
import fteatro from '../assets/svg/flecha_55c1c840-2e9a-40a2-89fb-008d2989de19.svg';
import fcine from '../assets/svg/flecha_bd226083-2579-496d-a1dd-1aa9a08a6855.svg';
import fdanza from '../assets/svg/flecha_cfb8cd34-9d1a-42ba-bcde-c1d4f992de34.svg';
import fbertsolarismo from '../assets/svg/flecha_a6329d46-f5ae-4b5e-82ba-facf7ffb6919.svg';
import finfantil from '../assets/svg/flecha_f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c.svg';
import ftaller from '../assets/svg/flecha_6eb6e65a-3a54-4028-8664-37f693b3fe5f.svg';
import fexposicion from '../assets/svg/flecha_9554edd0-52b9-44fa-b972-f537cc566508.svg';
import fconferencia from '../assets/svg/flecha_432671dd-b7e0-4e04-8938-a761f815e89f.svg';
import fjornada from '../assets/svg/flecha_a72db43b-b4ee-454c-aad5-01a61cbaf361.svg';
import fconcurso from '../assets/svg/flecha_86414ffb-4301-4678-9da3-43c3944edc38.svg';
import fferia from '../assets/svg/flecha_bc3fab61-c2b2-4a7f-a240-27513aa7b0e3.svg';
import ffiesta from '../assets/svg/flecha_59143b39-414e-4658-9348-c9463716365d.svg';
import ffestival from '../assets/svg/flecha_ad32f50b-8eb5-453a-a5a7-2ae1264a99cc.svg';
import fotras from '../assets/svg/flecha_e3450f47-c20a-4409-8116-c25b18239af6.svg';


const FULL_DATA_QUERY = gql`
  query GetPlaces($lang: String!, $day: String!, $provinceNames: [String!], $townIds: [String!], $postcodes: [String!], $placeIds: [String!], $placeTypeIds: [String!],  $eventLangIds: [String!], $eventTypeIds: [String!], $lat: Float, $lon: Float, $radius: Int, $startTime: String, $endTime: String ) {
    panels(lang: $lang, day: $day, provinceNames: $provinceNames, townIds: $townIds, postcodes: $postcodes, placeIds: $placeIds,  placeTypeIds: $placeTypeIds, eventLangIds: $eventLangIds, eventTypeIds: $eventTypeIds, lat: $lat, lon: $lon, radius: $radius, startTime: $startTime, endTime: $endTime) {
      summary {
        eventsCount
        townsCount
        startTimeMin
        endTimeMax
        distanceMax
      }
      counts {
        places {
          id
          name
          count
        },
        eventTypes {
          id
          name
          count
        },
        provinces {
          id
          name
          count
        },
        towns {
          id
          name
          count
        },
        eventLangs {
          id
          name
          count
        },
        postcodes {
          id
          name
          count
        },
        placeTypes{
        id
        name
        count
      }
      }
      panels {
        summary {
          eventsCount
          startTimeMin
          endTimeMax
          distanceMax
        }
        townName
        panel {
          count
          itineraries {
            count
            startTimeMin
            endTimeMax
            events {
              id
              name
              start
              end
              imageUrl
              url
              price
              place {
                name
                address
                postcode
                distance
                timetable
                point {
                  lat
                  lon
                }
                type {
                  name
                }
                town {
                  id
                  name
                }
              }
              type {
                id
                name
              }
              langs {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default {
  components: {
    TimeFilter,
    ProvinceFilter,
    TypeFilter,
    LanguageFilter,
    TownFilter,
    PostcodeFilter,
    PlaceFilter,
    PlaceTypeFilter,
    DistanceFilter,
    LanguageSelector,
    EventModal,
    DatePicker,
    RandomEvents,
    PrivacyPolicyModal,
  },
  props: {
    lang: {
      type: String,
      default: 'eu'
    }
  },
  setup(props, { emit }) {
    const showContactForm = ref(true);
    const selectedProvinces = ref([]);
    const selectedTypes = ref([]);
    const selectedLanguages = ref([]);
    const selectedTowns = ref([]);
    const selectedPostcodes = ref([]);
    const selectedPlaces = ref([]);
    const selectedPlaceTypes = ref([]);
    const selectedDistance = ref(0);

    const selectedStartTime = ref(null);
    const selectedEndTime = ref(null);
    const language = ref('eu');
    const townsResult = ref([]);
    const provincesResult = ref([]);
    const placesResult = ref([]);
    const eventTypesResult = ref([]);
    const placeTypesResult = ref([]);
    const languagesResult = ref([]);
    const postcodesResult = ref([]);
    const summary = ref([]);
    const panelSummary = ref([]);
    const numberOfEvents = ref(0);
    const filtersReady = ref(false);
    const selectedTownId = ref(null);
    const itinerariesPerPage = ref(1); // número de itinerarios por página
    const currentPage = ref(0);
    const selectedIndex = ref(0);

    const today = new Date(); // Fecha de hoy
    const selectedDate = ref(today.toISOString().split('T')[0]); // Fecha seleccionada, por defecto hoy

    const painted = ref(false);
    const addedEventIds = new Set(); // Usar un conjunto para rastrear IDs de eventos ya añadidos
    const addedEventTypes = new Set();

    const isModalVisible = ref(false); // Estado de visibilidad del modal
    const selectedEvent = ref({}); // Evento seleccionado

    const date = ref("");
    const minDate = ref(new Date());
    const maxDate = ref(new Date('2025-02-28'));
    const startTimeMin = ref(null);
    const endTimeMax = ref(null);

    // Variables reactivas para los tres días
    //  const day = ref(''); // Primer día (seleccionado)
    const day = ref(selectedDate.value); // Primer día (hoy)
    const nextDay = ref(new Date(selectedDate.value)); // Día siguiente
    const dayAfterNext = ref(new Date(selectedDate.value)); // Día después de mañana

    const instance = getCurrentInstance();
    const { proxy } = instance;
    const primevue = usePrimeVue();

    const itinerariesCount = ref(0);
    const isSending = ref(false);
    const buttonImage = computed(() => {
      return isSending.value ? time : right;
    });

    const eventColors = {
      "a3f8b76f-1830-43a6-bc4a-474b5f55bd61": "#7369BC", //musica
      "55c1c840-2e9a-40a2-89fb-008d2989de19": "#D98950", //teatro
      "bd226083-2579-496d-a1dd-1aa9a08a6855": "#4388CD", //cine
      "cfb8cd34-9d1a-42ba-bcde-c1d4f992de34": "#D46C6E", //danza
      "a6329d46-f5ae-4b5e-82ba-facf7ffb6919": "#3B9363", //bertsolarismo
      "f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c": "#D0983E", //infantil
      "6eb6e65a-3a54-4028-8664-37f693b3fe5f": "#DA6C38", //taller
      "9554edd0-52b9-44fa-b972-f537cc566508": "#41AAC4", //exposición
      "432671dd-b7e0-4e04-8938-a761f815e89f": "#84B036", //conferencia
      "a72db43b-b4ee-454c-aad5-01a61cbaf361": "#778D63", //jornada
      "86414ffb-4301-4678-9da3-43c3944edc38": "#AB53D1", //concurso
      "bc3fab61-c2b2-4a7f-a240-27513aa7b0e3": "#D75858", //feria
      "59143b39-414e-4658-9348-c9463716365d": "#EA5786", //fiesta
      "ad32f50b-8eb5-453a-a5a7-2ae1264a99cc": "#1AAB9D", //festival
      "e3450f47-c20a-4409-8116-c25b18239af6": "#6692AA", //otras
    };

    filterState.nroIteracciones = 0;
    const getIconSrc = (id) => {
      switch (id) {
        case 'a3f8b76f-1830-43a6-bc4a-474b5f55bd61': // música
          return musica;
        case '55c1c840-2e9a-40a2-89fb-008d2989de19': // teatro
          return teatro;
        case 'bd226083-2579-496d-a1dd-1aa9a08a6855': // cine
          return cine;
        case 'cfb8cd34-9d1a-42ba-bcde-c1d4f992de34': // danza
          return danza;
        case 'a6329d46-f5ae-4b5e-82ba-facf7ffb6919': // bertsolarismo
          return bertsolarismo;
        case 'f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c': // infantil
          return infantil;
        case '6eb6e65a-3a54-4028-8664-37f693b3fe5f': // taller
          return taller;
        case '9554edd0-52b9-44fa-b972-f537cc566508': // exposición
          return exposicion;
        case '432671dd-b7e0-4e04-8938-a761f815e89f': // conferencia
          return conferencia;
        case 'a72db43b-b4ee-454c-aad5-01a61cbaf361': // jornada
          return jornada;
        case '86414ffb-4301-4678-9da3-43c3944edc38': // concurso
          return concurso;
        case 'bc3fab61-c2b2-4a7f-a240-27513aa7b0e3': // feria
          return feria;
        case '59143b39-414e-4658-9348-c9463716365d': // fiesta
          return fiesta;
        case 'ad32f50b-8eb5-453a-a5a7-2ae1264a99cc': // festival
          return festival;
        case 'e3450f47-c20a-4409-8116-c25b18239af6': // otras
          return otras;
        default:
          return otras; // Icono por defecto si no hay coincidencia
      }
    };

    const getArrowSrc = (id) => {
      switch (id) {
        case 'a3f8b76f-1830-43a6-bc4a-474b5f55bd61': // música
          return fmusica;
        case '55c1c840-2e9a-40a2-89fb-008d2989de19': // teatro
          return fteatro;
        case 'bd226083-2579-496d-a1dd-1aa9a08a6855': // cine
          return fcine;
        case 'cfb8cd34-9d1a-42ba-bcde-c1d4f992de34': // danza
          return fdanza;
        case 'a6329d46-f5ae-4b5e-82ba-facf7ffb6919': // bertsolarismo
          return fbertsolarismo;
        case 'f2f0ebab-7ca2-4ba7-8e02-9e396b45f86c': // infantil
          return finfantil;
        case '6eb6e65a-3a54-4028-8664-37f693b3fe5f': // taller
          return ftaller;
        case '9554edd0-52b9-44fa-b972-f537cc566508': // exposición
          return fexposicion;
        case '432671dd-b7e0-4e04-8938-a761f815e89f': // conferencia
          return fconferencia;
        case 'a72db43b-b4ee-454c-aad5-01a61cbaf361': // jornada
          return fjornada;
        case '86414ffb-4301-4678-9da3-43c3944edc38': // concurso
          return fconcurso;
        case 'bc3fab61-c2b2-4a7f-a240-27513aa7b0e3': // feria
          return fferia;
        case '59143b39-414e-4658-9348-c9463716365d': // fiesta
          return ffiesta;
        case 'ad32f50b-8eb5-453a-a5a7-2ae1264a99cc': // festival
          return ffestival;
        case 'e3450f47-c20a-4409-8116-c25b18239af6': // otras
          return fotras;
        default:
          return fotras; // Icono por defecto si no hay coincidencia
      }
    };

    const getEventColor = (eventId) => {
      return eventColors[eventId] || '#302F2F'; // Color por defecto negro
    };

    const isSelected = (date) => {
      if (date === '1970-01-01') {
        selectedDate.value = new Date().toISOString().split('T')[0];
        date = new Date().toISOString().split('T')[0];
      }
      return date === selectedDate.value;
    };

    const onDateSelected = (dayStr) => {
      dayStr = dayStr;
    };

    watch(selectedDate, (newValue) => {
      if (newValue === '1970-01-01') {
        newValue = new Date().toISOString().split('T')[0];
      }
    });

    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    watch(date, (newValue) => {
      const newSelectedDate = new Date(newValue);
      selectedDate.value = formatDate(newSelectedDate);
      day.value = selectedDate.value;

      const newNextDay = new Date(newSelectedDate);
      newNextDay.setDate(newNextDay.getDate() + 1);
      nextDay.value = formatDate(newNextDay);

      const newDayAfterNext = new Date(newSelectedDate);
      newDayAfterNext.setDate(newDayAfterNext.getDate() + 2);
      dayAfterNext.value = formatDate(newDayAfterNext);
    });


    const convertMinutesToTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`; // Asegúrate de que esto retorne un string
    };

    const events = computed(() => {
      if (eventsResult.value) {
        const panelsArray = eventsResult.value.panels?.panels; // Usa el operador de encadenamiento opcional

        if (Array.isArray(panelsArray)) {
          return panelsArray.flatMap((panelObj) =>
            panelObj.panel?.itineraries?.flatMap(itinerary => itinerary.events) || []
          );
        }
      }
      return [];
    });
    addedEventTypes.clear();
    addedEventIds.clear();
    // Realizar la consulta de lugares
    const { result: fullDataResult, error: queryError, refetch, loading } = useQuery(FULL_DATA_QUERY, {
      lang: props.lang,
      day: formatDateToYYMMDD(selectedDate.value),
      provinceNames: filterState.selectedProvince ? [filterState.selectedProvince] : null,
      townIds: filterState.selectedTown ? [filterState.selectedTown] : null,
      postcodes: filterState.selectedPostcode ? [filterState.selectedPostcode] : null,
      placeTypeIds: filterState.selectedPlaceType ? [filterState.selectedPlaceType] : null,
      placeIds: filterState.selectedPlace ? [filterState.selectedPlace] : null,
      eventLangIds: filterState.selectedLangId ? [filterState.selectedLangId] : null,
      eventTypeIds: filterState.selectedTypeId ? [filterState.selectedTypeId] : null,
      lat: filterState.latitude !== undefined && filterState.latitude !== '' ? parseFloat(filterState.latitude) : null,
      lon: filterState.longitude !== undefined && filterState.longitude !== '' ? parseFloat(filterState.longitude) : null,
      radius: filterState.selectedDistance > 0 ? parseInt(filterState.selectedDistance * 1000, 10) : null,  // Convertir kilómetros a metros si existe un valor
      startTime: selectedStartTime.value !== null ? convertMinutesToTime(selectedStartTime.value) : null,
      endTime: selectedEndTime.value !== null ? convertMinutesToTime(selectedEndTime.value) : null
    });

    let lastFilters = {};

    watch(
      () => ({
        newDay: formatDateToYYMMDD(selectedDate.value),
        province: filterState.selectedProvince,
        postcode: filterState.selectedPostcode,
        town: filterState.selectedTown,
        placeType: filterState.selectedPlaceType,
        place: filterState.selectedPlace,
        eventLang: filterState.selectedLangId,
        eventType: filterState.selectedTypeId,
        language: filterState.language,
        latitude: filterState.latitude,
        longitude: filterState.longitude,
        ratio: filterState.selectedDistance * 1000,
        startTime: filterState.selectedStartTime,
        endTime: filterState.selectedEndTime
      }),
      ({ province, postcode, town, place, placeType, eventLang, eventType, language, latitude, longitude, ratio, startTime, endTime }) => {
        // Convertir los valores a arrays normales si existen, o a null si no existen
        addedEventTypes.clear();
        addedEventIds.clear();
        const provinceNames = province
          ? Array.isArray(province)
            ? Array.from(province)
            : [province]
          : null;

        const postcodes = postcode
          ? Array.isArray(postcode)
            ? Array.from(postcode)
            : [postcode]
          : null;

        const townIds = town
          ? Array.isArray(town)
            ? Array.from(town)
            : [town]
          : null;

        const placeTypeIds = placeType
          ? Array.isArray(placeType)
            ? Array.from(placeType)
            : [placeType]
          : null;

        const placeIds = place
          ? Array.isArray(place)
            ? Array.from(place)
            : [place]
          : null;

        const eventLangIds = eventLang
          ? Array.isArray(eventLang)
            ? Array.from(eventLang)
            : [eventLang]
          : null;

        const eventTypeIds = eventType
          ? Array.isArray(eventType)
            ? Array.from(eventType)
            : [eventType]
          : null;

        const radi = ratio !== undefined && ratio !== null && ratio > 0
          ? parseInt(ratio, 10) // Asegurarse de que el ratio sea un número entero
          : null;

        addedEventTypes.clear();
        addedEventIds.clear();
        filterState.nroIteracciones = filterState.nroIteracciones + 1;
        // Realizar una única llamada a refetch con todos los filtros
       
          refetch({
            lang: language || 'eu',
            day: formatDateToYYMMDD(selectedDate.value),
            provinceNames: provinceNames,
            postcodes: postcodes,
            townIds: townIds,
            placeTypeIds: placeTypeIds,
            placeIds: placeIds,
            eventLangIds: eventLangIds,
            eventTypeIds: eventTypeIds,
            lat: latitude,
            lon: longitude,
            radius: radi,
            startTime: startTime,
            endTime: endTime
          });

          // Gestionar el resultado de placesResult
          watch(fullDataResult, () => {
            addedEventTypes.clear();
            addedEventIds.clear();
            if (fullDataResult.value) {

              townsResult.value = fullDataResult.value.panels.counts.towns.map(town => ({
                id: town.id,
                name: town.name,
                count: town.count
              }));

              // Asignación para eventTypesResult
              eventTypesResult.value = fullDataResult.value.panels.counts.eventTypes.map(eventType => ({
                id: eventType.id,
                name: eventType.name,
                count: eventType.count
              }));

              // Asignación para provincesResult
              provincesResult.value = fullDataResult.value.panels.counts.provinces.map(province => ({
                id: province.id,
                name: province.name,
                count: province.count
              }));

              // Asignación para placesResult
              placesResult.value = fullDataResult.value.panels.counts.places.map(place => ({
                id: place.id,
                name: place.name,
                count: place.count
              }));

              // Asignación para placeTypesResult
              placeTypesResult.value = fullDataResult.value.panels.counts.placeTypes.map(placeType => ({
                id: placeType.id,
                name: placeType.name,
                count: placeType.count
              }));

              // Asignación para languagesResult
              languagesResult.value = fullDataResult.value.panels.counts.eventLangs.map(eventLang => ({
                id: eventLang.id,
                name: eventLang.name,
                count: eventLang.count
              }));

              // Asignación para postcodesResult
              postcodesResult.value = fullDataResult.value.panels.counts.postcodes.map(postcode => ({
                id: postcode.id,
                name: postcode.name,
                count: postcode.count
              }));

              numberOfEvents.value = fullDataResult.value.panels.summary.eventsCount || 0;
              filtersReady.value = true;
              if (selectedIndex.value === -1) {
                selectedIndex.value = 0; // Asigna 0 si no se encontró el índice
              }
              summary.value = fullDataResult.value.panels.summary;
              if (fullDataResult.value.panels.panels[selectedIndex.value]) {
                itinerariesCount.value = fullDataResult.value.panels.panels[selectedIndex.value].panel.count || 0;
              } else {
                selectedIndex.value = 0;
              }
              if (townsResult.value.length > 0) {
                selectedTownId.value = townsResult.value[0].id; // Establecer el primer town como seleccionado
              }
            }

            // Manejo de errores en placesResult
            if (queryError.value) {
              console.error("Error fetching the full query:", queryError.value);
            }
          });
      },
  { immediate: true } 

    );



    const eventsResult = computed(() => {
      return fullDataResult.value?.panels?.panels;
    });

    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            filterState.latitude = latitude;
            filterState.longitude = longitude;
          },
          error => {
            // Aquí manejamos el caso en que el usuario niega el permiso
            if (error.code === error.PERMISSION_DENIED) {
              console.log('El usuario ha denegado el acceso a la geolocalización.');
            }
          }
        );
      } else {
        console.error('Geolocalización no es soportada por este navegador.');
      }
    };

    const getEventsForCurrentPage = computed(() => {
      return getEventsForTimeItineraryAndTown(time, currentPage.value, selectedTownId.value);
    });


    const getEventsForTimeItineraryAndTown = (time, itineraryIndex, selectedTownId, currentPage) => {
      let foundEvent = false;
      if (!fullDataResult.value || !Array.isArray(fullDataResult.value.panels.panels)) return [];

      const eventsForItinerary = ref([]); // Usamos `ref` para que Vue reaccione a los cambios
      fullDataResult.value.panels.panels.forEach(panel => {
        const itineraries = panel.panel.itineraries;
        const startIndex = currentPage * itinerariesPerPage.value + (itineraryIndex - 1);

        if (startIndex < itineraries.length) {
          const events = itineraries[startIndex]?.events || [];
          events.forEach(event => {
            const eventStartMinutes = getMinutesFromTime(event.start);
            const eventEndMinutes = getMinutesFromTime(event.end);



            const nextDayStartMinutes = 24 * 60; // 00:00 del siguiente día
            const twoAMNextDayMinutes = nextDayStartMinutes + (2 * 60); // 02:00 AM del siguiente día


            // Verifica si el evento ocurre en el rango de tiempo actual
            if (event.place && event.place.town && event.place.town.id === selectedTownId) {
              // Condición ajustada para incluir eventos que terminan después de la medianoche
              if (
                (eventStartMinutes <= time && eventEndMinutes > time) ||  // Eventos dentro del día
                (eventStartMinutes < nextDayStartMinutes && eventEndMinutes >= nextDayStartMinutes && time >= nextDayStartMinutes && time < twoAMNextDayMinutes)  // Eventos después de medianoche
              ) {
                const eventDurationMinutes = eventEndMinutes - eventStartMinutes;
                const cellsOccupied = Math.floor(eventDurationMinutes / 30);

                const offsetMinutes = eventStartMinutes % 30;

                foundEvent = true;
                // Verificar si el evento ya ha sido añadido
                if (!addedEventIds.has(event.id)) {
                  addedEventIds.add(event.id);

                  if (cellsOccupied > 0) {
                    markCellAsOccupied(time, itineraryIndex);
                  }

                  const eventWithCellsOccupied = {
                    ...event, // Copia las propiedades originales del evento
                    cellsOccupied, // Añade la nueva propiedad cellsOccupied
                    offsetMinutes
                  };

                  // Verificar y agregar el tipo de evento
                  if (event.type?.id && event.type?.name) {
                    const eventType = { id: event.type.id, name: event.type.name };
                    if (![...addedEventTypes].some(item => item.id === eventType.id)) {
                      addedEventTypes.add(eventType);
                    }
                  }

                  // Agregar el nuevo objeto a la lista de eventos
                  eventsForItinerary.value.push(eventWithCellsOccupied);
                }
              }
            }
          });
        }
      });

      if (!foundEvent) {
        const emptyEvent = {
          id: `empty-${itineraryIndex}-${time}`,
          name: 'Sin Evento',
          cellsOccupied: 0,
          place: { name: 'Sin Lugar', town: { id: null } }
        };
        eventsForItinerary.value.push(emptyEvent);
      }
      return eventsForItinerary.value;
    };

    watchEffect(() => {
      // Verificar si 'townsResult.value' tiene datos antes de continuar
      if (townsResult.value && townsResult.value.length > 0) {
        // Si 'selectedTownId.value' está indefinido, asignar el primer municipio
        if (!selectedTownId.value) {
          selectedTownId.value = townsResult.value[0].id;
        }

        // Buscar el índice del municipio seleccionado
        selectedIndex.value = townsResult.value.findIndex(town => town.id === selectedTownId.value);
        if (selectedIndex.value === -1) {
          selectedIndex.value = 0; // Asigna 0 si no se encontró el índice
          itinerariesCount.value = fullDataResult.value.panels.panels[selectedIndex.value].panel.count || 0;
        }

        // Actualizar el resumen del panel usando la función existente
        updateSelectedIndex();
      }
    });

    const updateSelectedIndex = () => {
      addedEventTypes.clear();
      addedEventIds.clear();
      selectedIndex.value = townsResult.value.findIndex(town => town.id === selectedTownId.value);

      if (selectedIndex.value > 0) {
        itinerariesCount.value = fullDataResult.value.panels.panels[selectedIndex.value].panel.count;
      } else {
        selectedIndex.value = 0;
      }

      let selectedPanel = null;
      if (fullDataResult.value && Array.isArray(fullDataResult.value.panels.panels)) {
        // Verifica si el índice seleccionado es válido dentro del array de panels
        selectedPanel = fullDataResult.value.panels.panels[selectedIndex.value];
      }


      if (selectedPanel && selectedPanel.summary) {
        panelSummary.value = selectedPanel.summary;

        if (panelSummary.value.startTimeMin) {
          startTimeMin.value = getMinutesFromHour(panelSummary.value.startTimeMin); // Convertir startTimeMin a minutos
        }
        if (panelSummary.value.endTimeMax) {
          endTimeMax.value = getMinutesFromHour(panelSummary.value.endTimeMax);     // Convertir endTimeMax a minutos
        }
      }

      currentPage.value = 0;
    };




    // Función para obtener minutos a partir del formato de tiempo
    const getMinutesFromTime = (time) => {
      const [hours, minutes] = time.split(' ')[1].split(':').map(Number);
      const totalMinutes = hours * 60 + minutes;

      // Si es medianoche o más tarde, lo consideramos parte del día siguiente
      if (hours === 0 || hours < 8) {
        return totalMinutes + (24 * 60); // Sumar un día completo (1440 minutos)
      }

      return totalMinutes;
    };

    const getMinutesFromHour = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      const totalMinutes = hours * 60 + minutes;

      // Si es medianoche o más tarde, lo consideramos parte del día siguiente
      if (hours === 0 || hours < 8) {
        return totalMinutes + (24 * 60); // Sumar un día completo (1440 minutos)
      }
      return totalMinutes;
    };



    const timeSlots = ref(
      Array.from({ length: Math.ceil((endTimeMax - startTimeMin) / 30) }, (_, i) => startTimeMin + i * 30)
    );
    const formatTimeSlot = (time) => {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;

      const adjustedHours = hours % 24;

      return `${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const updateTimeSlots = () => {
      // Asegúrate de que startTimeMin y endTimeMax estén definidos y no sean nulos
      if (startTimeMin.value !== null && endTimeMax.value !== null) {
        // Generar timeSlots basados en startTimeMin y endTimeMax, con intervalos de 30 minutos
        const numSlots = Math.ceil((endTimeMax.value - startTimeMin.value) / 30);
        timeSlots.value = Array.from({ length: numSlots }, (_, i) => startTimeMin.value + i * 30);
      } else {

        timeSlots.value = []; // Asegúrate de que timeSlots esté vacío si los valores son inválidos
      }
    };

    watchEffect(() => {
      updateTimeSlots();
    });

    const totalPages = computed(() => {
      if (fullDataResult.value && Array.isArray(fullDataResult.value.panels.panels) && selectedTownId.value !== undefined) {
        // Encuentra el panel que tiene itinerarios
        const panel = fullDataResult.value.panels.panels.find(
          panel => panel.panel.itineraries && panel.panel.itineraries.length > 0
        );

        if (panel) {
          if (selectedIndex.value > 0) {
            itinerariesCount.value = fullDataResult.value.panels.panels[selectedIndex.value].panel.count;
          } else {
            selectedIndex.value = 0;
          }
          // Obtiene la cantidad de itinerarios en el panel
          const totalItineraries = itinerariesCount.value;
          return Math.ceil(totalItineraries / itinerariesPerPage.value); // Asegúrate de usar .value para acceder al valor
        }
      }
      return 0; // Valor por defecto si no se cumplen las condiciones
    });

    const getItinerariesCountForTown = computed(() => {
      if (!fullDataResult.value || !Array.isArray(fullDataResult.value.panels.panels)) return 0;

      // Encontrar los itinerarios correspondientes al municipio seleccionado
      const panel = fullDataResult.value.panels.panels.find(
        panel => panel.panel.itineraries && panel.panel.itineraries.length > 0
      );
      return panel ? panel.panel.itineraries.length : 0;
    });

    const nextPage = () => {
      addedEventTypes.clear();
      addedEventIds.clear();
      if (currentPage.value < totalPages.value - 1) {
        currentPage.value++;
      }
    };

    const previousPage = () => {
      addedEventTypes.clear();
      addedEventIds.clear();
      if (currentPage.value > 0) {
        currentPage.value--;
      }
    };

    const isDarkMode = computed(() => {
      return typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    const leftArrowSrc = computed(() => {
      if (currentPage.value === 0) {
        return isDarkMode.value ? LeftGreen : LeftGray;
      }
      return isDarkMode.value ? LeftSage : LeftBlack;
    });

    const rightArrowSrc = computed(() => {
      if (currentPage.value >= totalPages.value - 1) {
        return isDarkMode.value ? RightGreen : RightGray;
      }
      return isDarkMode.value ? RightSage : RightBlack;
    });

    const calculateItinerariesPerPage = () => {
      addedEventTypes.clear();
      addedEventIds.clear(); // Limpia el conjunto antes de cada nueva búsqueda de eventos

      const columnWidth = 290;
      const screenWidth = window.innerWidth;

      return Math.max(1, Math.floor(screenWidth / columnWidth));
    };

    watch(itinerariesPerPage, () => {
      addedEventTypes.clear();
      addedEventIds.clear();
      updateSelectedIndex();
    });


    // Inicializa los días (hoy, mañana, pasado mañana)
    const initializeDays = () => {
      const date1 = new Date(today);
      date1.setDate(today.getDate() + 1);
      nextDay.value = date1.toISOString().split('T')[0];

      const date2 = new Date(today);
      date2.setDate(today.getDate() + 2);
      dayAfterNext.value = date2.toISOString().split('T')[0];
    };
    // Cambia la fecha seleccionada y actualiza el botón seleccionado
    const selectDate = (date) => {
      if (date === '1970-01-01') {
        date = new Date().toISOString().split('T')[0];
      }
      selectedDate.value = date;
    };

    const formatMonth = (day) => {
      const date = new Date(day);
      return format(date, 'MMM'); // Devuelve las tres primeras letras del mes
    };

    const formatDay = (date) => {
      const parsedDate = new Date(date);
      return format(parsedDate, 'd');  // Esto devolverá el día con dos dígitos.
    };
    // Función para retroceder días (flecha izquierda)
    const prevDays = () => {
      if (selectedDate.value === day.value) {
        // Si el primer botón está seleccionado, retrocede todos los días
        const newDate = new Date(day.value);
        newDate.setDate(newDate.getDate() - 1);
        day.value = newDate.toISOString().split('T')[0];

        const newNextDay = new Date(nextDay.value);
        newNextDay.setDate(newNextDay.getDate() - 1);
        nextDay.value = newNextDay.toISOString().split('T')[0];

        const newDayAfterNext = new Date(dayAfterNext.value);
        newDayAfterNext.setDate(newDayAfterNext.getDate() - 1);
        dayAfterNext.value = newDayAfterNext.toISOString().split('T')[0];

        // Actualiza la fecha seleccionada para reflejar el primer día
        selectedDate.value = day.value;
      } else if (selectedDate.value === nextDay.value) {
        // Si el segundo botón está seleccionado, retrocede solo al primer día
        selectedDate.value = day.value;
      } else if (selectedDate.value === dayAfterNext.value) {
        // Si el tercer botón está seleccionado, retrocede solo al segundo día
        selectedDate.value = nextDay.value;
      }
    };

    // Función para avanzar días (flecha derecha)
    // Función para avanzar días (flecha derecha)
    const nextDays = () => {
      // Si el primer día está seleccionado, pasa a seleccionar el segundo sin cambiar las fechas
      if (selectedDate.value === day.value) {
        selectedDate.value = nextDay.value;
      }
      // Si el segundo día está seleccionado, pasa a seleccionar el tercero sin cambiar las fechas
      else if (selectedDate.value === nextDay.value) {
        selectedDate.value = dayAfterNext.value;
      }
      // Si el tercer día está seleccionado, avanza las fechas y mantén el tercer botón seleccionado
      else {
        const newDate = new Date(day.value);
        newDate.setDate(newDate.getDate() + 1);
        day.value = newDate.toISOString().split('T')[0];

        const newNextDay = new Date(nextDay.value);
        newNextDay.setDate(newNextDay.getDate() + 1);
        nextDay.value = newNextDay.toISOString().split('T')[0];

        const newDayAfterNext = new Date(dayAfterNext.value);
        newDayAfterNext.setDate(newDayAfterNext.getDate() + 1);
        dayAfterNext.value = newDayAfterNext.toISOString().split('T')[0];

        // Mantén el tercer día seleccionado después de avanzar las fechas
        selectedDate.value = dayAfterNext.value;
      }
    };


    // Deshabilitar botón de retroceso si el primer día es hoy
    const isToday = computed(() => {
      const todayStr = today.toISOString().split('T')[0];
      return day.value === todayStr;
    });

    const isMaxDate = computed(() => {
      // Definir la fecha máxima en formato YYYY-MM-DD
      const maxDateStr = '2025-02-28';

      // Función para asegurarse de que la fecha esté en el formato correcto (YYYY-MM-DD)
      const formatDate = (date) => {
        // Si date es un objeto Date, lo formateamos. Si no, ya debería estar en el formato correcto.
        if (date instanceof Date) {
          return date.toISOString().split('T')[0];
        }
        // Si ya es una cadena (por ejemplo, 'YYYY-MM-DD'), la devolvemos tal cual
        return date;
      };

      // Comprobar si alguna de las fechas ha llegado o supera la fecha máxima
      return [day.value, nextDay.value, dayAfterNext.value].some((date) => formatDate(date) >= maxDateStr);
    });

    const isMaxDateSelected = computed(() => {
      return [day.value, nextDay.value, dayAfterNext.value].some(
        selectedDay => selectedDay === maxDate
      );
    });

    // Función para abrir un calendario (implementa lo que necesites)
    const openCalendar = () => {
      // Lógica para abrir un calendario
    };
    function formatDateToYYMMDD(date) {
      if (!date) return null;

      const d = new Date(date);
      const year = String(d.getFullYear()).slice(2); // Obtener los últimos dos dígitos del año
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Asegurarse de que el mes tenga dos dígitos
      const day = String(d.getDate()).padStart(2, '0'); // Asegurarse de que el día tenga dos dígitos

      return `${year}-${month}-${day}`; // Retornar en el formato YY-MM-DD
    }

    // Estado para rastrear las celdas ocupadas
    const occupiedCells = ref([]);

    // Método para verificar si una celda está vacía
    const isCellEmpty = (time, itineraryIndex) => {
      return !occupiedCells.value.some(cell => cell.time === time && cell.itineraryIndex === itineraryIndex);
    };

    // Método para marcar la celda como ocupada
    const markCellAsOccupied = (time, itineraryIndex) => {
      occupiedCells.value.push({ time, itineraryIndex });
    };

    const form = ref({
      subject: '',
      message: '',
    });

    const notification = ref({
      show: false,
      message: '',
      isError: false
    });

    const sendEmail = () => {
      isSending.value = true;
      emailjs
        .send(
          'service_hc9ytgq',
          'template_lk619b5',
          {
            subject: form.value.subject,
            message: form.value.message,
          },
          'Nw0Tc9XtyEuFl--j_'
        )
        .then(
          () => {
            notification.value = {
              show: true,
              message: proxy.$t('notification.thanks'),
              isError: false
            };
            form.value = {
              subject: '',
              message: ''
            };
            setAutoCloseNotification();
          },
          (error) => {
            notification.value = {
              show: true,
              message: proxy.$t('notification.notSend'),
              isError: true
            };
            setAutoCloseNotification();
          }
        )
        .finally(() => {
          isSending.value = false; // Restaura el estado cuando termine el envío
        });
      addedEventTypes.clear();
      addedEventIds.clear();
    };

    const setAutoCloseNotification = () => {
      setTimeout(() => {
        notification.value.show = false;
      }, 5000);
    };

    function closeNotification() {
      notification.value.show = false;
    }

    const reloadPage = () => {
      window.location.reload();  // Recargar la página
    };

    // Función para abrir el modal y seleccionar un evento
    const openModal = (event) => {
      addedEventTypes.clear();
      addedEventIds.clear();
      selectedEvent.value = event;
      isModalVisible.value = true;
    };

    // Función para cerrar el modal
    const closeModal = () => {
      addedEventTypes.clear();
      addedEventIds.clear();
      isModalVisible.value = false;
    };



    // Función para actualizar el idioma en PrimeVue
    const updateLocale = (language) => {
      if (language === 'eu') {
        primevue.config.locale = {
          today: 'Gaur',
          clear: 'Ezabatu',
          dateFormat: 'yy/mm/dd',
          firstDayOfWeek: 1,
          monthNames: ['Urtarrila', 'Otsaila', 'Martxoa', 'Apirila', 'Maiatza', 'Ekaina', 'Uztaila', 'Abuztua', 'Iraila', 'Urria', 'Azaroa', 'Abendua'],
          dayNamesMin: ['Ig', 'As', 'At', 'Az', 'Og', 'Or', 'La'],
        };
      } else if (language === 'es') {
        primevue.config.locale = {
          today: 'Hoy',
          clear: 'Borrar',
          dateFormat: 'yy/mm/dd',
          firstDayOfWeek: 1,
          monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
        };
      } else if (language === 'en') {
        primevue.config.locale = {
          today: 'Today',
          clear: 'Clear',
          dateFormat: 'yy/mm/dd',
          firstDayOfWeek: 1,
          monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        };
      }
    };


    // Observa los cambios en el idioma y actualiza el locale
    watch(
      () => filterState.language,
      (newLanguage) => {
        updateLocale(newLanguage);
      }
    );

    onMounted(() => {
      // Inicializa los días cuando se monta el componente
      initializeDays();
      getUserLocation();
      updateLocale(filterState.language);
      if (townsResult.value.length > 0) {
        selectedTownId.value = townsResult.value[0].id;
        //   updateEventsForSelectedTown(); // Cargar los eventos al inicio
      }

      const isDarkMode = computed(() => {
        return typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      });



      watchEffect(() => {
        if (loading.value) {
          return;
        }
      });
      itinerariesPerPage.value = calculateItinerariesPerPage();

      // Escucha cambios en el tamaño de la ventana
      window.addEventListener('resize', handleResize);

    });

    const isPrivacyModalVisible = ref(false);

    const openPrivacyModal = () => {
      addedEventTypes.clear();
      addedEventIds.clear();
      isPrivacyModalVisible.value = true;
    };

    const closePrivacyModal = () => {
      addedEventTypes.clear();
      addedEventIds.clear();
      isPrivacyModalVisible.value = false;
    };

    onUnmounted(() => {
      // Limpia el listener al desmontar el componente
      window.removeEventListener('resize', handleResize);
    });

    const handleResize = () => {
      itinerariesPerPage.value = calculateItinerariesPerPage();
    };

    const onProvinceChange = (newSelectedProvinces) => {
      selectedProvinces.value = newSelectedProvinces;
    };

    const onTypeChange = (newSelectedTypes) => {
      selectedTypes.value = newSelectedTypes;
    };

    const onTownChange = (newSelectedTowns) => {
      selectedTowns.value = newSelectedTowns;
    };

    const onPostcodeChange = (newSelectedPostcodes) => {
      selectedPostcodes.value = newSelectedPostcodes;
    };

    const onPlaceChange = (newSelectedPlaces) => {
      selectedPlaces.value = newSelectedPlaces;
    };

    const onPlaceTypeChange = (newSelectedPlaceTypes) => {
      selectedPlaceTypes.value = newSelectedPlaceTypes;
    };

    const onLanguageChange = (newSelectedLanguage) => {
      selectedLanguages.value = newSelectedLanguage;
    };

    const onDistanceChange = (newSelectedDistance) => {
      selectedDistance.value = newSelectedDistance;
    };

    const onTimeChange = (times) => {
      selectedStartTime.value = times[0];
      selectedEndTime.value = times[1];
    };

    const changeLanguage = (newLanguage) => {
      language.value = newLanguage;
    };

    return {
      events,
      selectedProvinces,
      selectedTypes,
      selectedLanguages,
      selectedTowns,
      selectedPostcodes,
      selectedEndTime,
      selectedStartTime,
      selectedPlaces,
      selectedPlaceTypes,
      selectedDistance,
      language,
      numberOfEvents,
      summary,
      panelSummary,
      onProvinceChange,
      onTypeChange,
      onTownChange,
      onPostcodeChange,
      onPlaceChange,
      onPlaceTypeChange,
      onLanguageChange,
      onDistanceChange,
      onTimeChange,
      changeLanguage,
      selectedProvinces,
      timeSlots,
      formatTimeSlot,
      getEventsForTimeItineraryAndTown,
      provincesResult,
      eventTypesResult,
      townsResult,
      placesResult,
      eventsResult,
      placeTypesResult,
      postcodesResult,
      languagesResult,
      loading,
      queryError,
      filtersReady,
      selectedTownId,
      totalPages,
      currentPage,
      nextPage,
      previousPage,
      itinerariesPerPage,
      updateSelectedIndex,
      selectedIndex,
      getEventsForCurrentPage,
      getItinerariesCountForTown,
      nextDay,
      dayAfterNext,
      selectDate,
      openCalendar,
      day,
      formatDateToYYMMDD,
      selectedDate,
      isToday,
      isMaxDate,
      prevDays,
      nextDays,
      painted,
      isCellEmpty,
      getEventsForTimeItineraryAndTown,
      openModal,
      closeModal,
      isModalVisible,
      selectedEvent,
      date,
      minDate,
      maxDate,
      onDateSelected,
      formatMonth,
      formatDay,
      isSelected,
      LeftBlack,
      LeftGray,
      leftArrowSrc,
      rightArrowSrc,
      RightBlack,
      RightGray,
      getEventColor,
      onUnmounted,
      getIconSrc,
      getArrowSrc,
      addedEventTypes,
      getUserLocation,
      getMinutesFromHour,
      startTimeMin,
      endTimeMax,
      itinerariesCount,
      reloadPage,
      form,
      sendEmail,
      notification,
      closeNotification,
      showContactForm,
      isPrivacyModalVisible,
      openPrivacyModal,
      buttonImage,
      closePrivacyModal,
      isSending,
      isMaxDateSelected,
      lastFilters
    };
  },
};

</script>
